/* AuditSummary.css */

.chartsFilter {
  border-style: groove;
  border-color: #a5bfdc; 
  padding: 2%;
  border-radius: 4px;
  margin-bottom: 2%;
  margin-top: 2%;
}



.row {
  display: flex;
  flex-direction: row; /* Arrange items in a row */
  gap: 40px; /* Adjust the space between items */
  align-items: center;
}


.audits-summary-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
 
}

.notesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  grid-gap: 20px; /* Adjust the gap between the items */
  margin: 20px; /* Optional: Adds some margin around the grid */
}




/* Responsive adjustments */
@media (max-width: 900px) {
  .row {
    flex-direction: column;
    align-items: center;
  }

  /* Update the .notesContainer for smaller screens */
  .notesContainer {
    grid-template-columns: 1fr; /* Change to single column */
  }
}