/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}



/* App Container */
.App {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #22c1c3;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #e7dabc, #22c1c3);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f1e6cd, #22c1c3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  

}

/* Typography */
h1 {
  color: #333;
  text-align: center;
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 30px;
}


/* Forms and Inputs */
form {
  margin-bottom: 20px;
}

input[type="text"],
select {
  padding: 8px 12px;
  margin-bottom: 5px;
  border: 1px solid #ccd0d5;
  width: calc(100% - 24px);
  font-size: 14px;
  display: block;
}



/* Tables */
.App table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2%;
}

.App th,
.App td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.App th {
  background-color: #b3ecf0;
  font-size: 14px;
}

.App td {
  font-size: 12px;
}

/* Column Widths */
.App th:nth-child(1),
.App td:nth-child(1) {
  width: 03%;
}

.App th:nth-child(2),
.App td:nth-child(2) {
  width: 05%;
}

.App th:nth-child(3),
.App td:nth-child(3) {
  width: 03%;
}

.App th:nth-child(4),
.App td:nth-child(4) {
  width: 08%;
}

.App th:nth-child(5),
.App td:nth-child(5) {
  width: 05%;
}

.App th:nth-child(6),
.App td:nth-child(6) {
  width: 10%;
}

.App th:nth-child(7),
.App td:nth-child(7) {
  width: 50%;
}

.App th:nth-child(8),
.App td:nth-child(8) {
  width: 15%;
}

.App th:nth-child(9),
.App td:nth-child(9) {
  width: 20%;
}


/* Buttons */

/* Edit Mode Input - Specific Style */
.App input.edit-mode-input,
.App select.edit-mode-input {
  border-radius: 0 ;
  font-size: 12px;
}





.download-btn {
  background-color: #10ac3a;

}

button {
  padding: 4px;
  border: none;
  background-color: #1877f2;
  color: white;
  cursor: pointer;
  font-size: 12px;
  border-radius: 15px;
}

button:hover {
  background-color: #e5b116;
}

/* Footer */
footer {

  text-align: center;
  padding: 20px;
}

footer p {
  font-size: 14px;
  margin-top: 10px;
}
.question {
  font-size: 11px;
  margin-top: 4px;
  text-decoration: none;
  }

/* Social Media Links */
.social-media {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 10px 0;
}

.social-icon {
  color: #fff;
  background-color: #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.social-icon:hover {
  background-color: #0056b3;
}

/* Specific Social Icon Colors */
.social-icon.linkedin {
  background-color: #0077b5;
}

.social-icon.linkedin:hover {
  background-color: #005693;
}

.social-icon.twitter {
  background-color: #1DA1F2;
}

.social-icon.twitter:hover {
  background-color: #1A91DA;
}

.social-icon.user {
  background-color: #333;
}

.social-icon.user:hover {
  background-color: #555;
}



/* Additional Styles */


.form-row {
  display: flex;
  /* Aligns children inline */
  gap: 10px;
  /* Adds some space between the elements */
  margin-bottom: 5px;
  /* Space below the row */
}

input[type="text"],
select,
textarea {
  padding: 8px 12px;
  border-radius: 20px;
  /* Maintains style consistency */
  border: 1px solid #ccd0d5;
  font-size: 14px;
  display: block;
}

textarea {
  width: calc(100% - 4px);
  /* Matches the input and select width */
  resize: vertical;
  /* Allows vertical resizing only */
  margin-bottom: 10px;
}

/* App.css or another relevant CSS file */



.auditor-display {
  display: flex;
  align-items: center; /* This ensures vertical centering of all children */
  justify-content: flex-start; /* Align items to the start of the container, updated for compatibility */
  margin-bottom: 10px;
}


.auditor-display h2 {
  margin: 0;
  margin-right: 10px; /* Space between the h2 and the button */
  flex-grow: 0; /* Prevent the h2 from growing to fill the container */
}

.edit-name-button {
  padding: 4px 8px; /* Adjust as needed for a smaller button */
  font-size: 0.6rem; /* Smaller text within the button */
  cursor: pointer; /* Change cursor to indicate clickable action */
  flex-grow: 0; /* Prevent the button from growing */
}


/* Add this to your CSS file */
.nav-link-active {
  border: 2px solid blue; /* Example active style, customize as needed */
}


/* Responsive Design */
@media (max-width: 768px) {

  button {
    padding: 4px 10px;
    font-size: 12px;
  }
  /* Column Widths */
.App th:nth-child(1),
.App td:nth-child(1) {
  width: 3%;
}

.App th:nth-child(2),
.App td:nth-child(2) {
  width: 10%;
}

.App th:nth-child(3),
.App td:nth-child(3) {
  width: 10%;
}

.App th:nth-child(4),
.App td:nth-child(4) {
  width: 45%;
}

.App th:nth-child(5),
.App td:nth-child(5) {
  width: 12%;
}

.App th:nth-child(6),
.App td:nth-child(6) {
  width: 12%;
}
}

/* Hide the orientation message by default */
.orientation-message {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  z-index: 100;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  font-size: 1.5rem;
}

/* Show the message in portrait mode on devices with a width of 768px or less */
@media (max-width: 768px) and (orientation: portrait) {
  .orientation-message {
    display: flex;
  }

  
}

.header {
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 2px 5px;
}

.header-summary {
  /*   border-color: #007bff;
    border-style: groove;
    border-radius: 8px; */
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 2px 5px;
  }
  
