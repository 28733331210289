.logout-button {
    padding: 5px 10px;
    background-color: #1877f2; /* Facebook blue */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    user-select: none; /* Prevent text selection */
    transition: background-color 0.3s, box-shadow 0.3s;
    outline: none;
}

.logout-button:hover {
    background-color: #166fe5; /* Slightly darker blue on hover */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.logout-button:active {
    background-color: #145cbd; /* Even darker blue when clicked */
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.logout-button:focus {
    box-shadow: 0 0 0 2px #e7f3ff; /* Focus ring for accessibility */
}

.btn1 {
    padding: 10px 20px;
    background-color: #18f26c; /* Facebook blue */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    display:flex;
    user-select: none; /* Prevent text selection */
    transition: background-color 0.3s, box-shadow 0.3s;
    outline: none;
    margin-top: 2%;
    margin-bottom: 2%;
}



.copy {
    padding: 5px 10px;
    background-color: #0fa854; /* Facebook blue */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    display:flex;
    user-select: none; /* Prevent text selection */
    transition: background-color 0.3s, box-shadow 0.3s;
    outline: none;
 
}

.user-button {
    padding: 5px 10px;
    background-color: #cbd4cd;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
  
    transition: background-color 0.3s, box-shadow 0.3s;
    outline: none;
}

