/* Pagination.css */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 20px auto; /* Center the container horizontally */
  width: fit-content; /* Adjust the width based on the content */
}

.pagination-container button {
  padding: 10px 15px;
  margin: 0 10px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.pagination-container button:hover {
  background-color: #0056b3;
}

.pagination-container button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-container span {
  font-size: 16px;
  font-weight: bold;
}
