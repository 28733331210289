/* NotesSummary.css */
.audits-summary-container {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}



.audits-summary-item {
  background-color: #e9ecef;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.audits-summary-item p {
  margin: 0;
  color: #212529;
  font-weight: bold;
}

/* AFESummary.css */
.legend-container {
  text-align: start;
  margin-bottom: 10px; /* Space between legend and chart */

}

.audits-summary-chart {
  width: 100%;
  height: 300px; /* Adjust height as needed */
}
