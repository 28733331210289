/* General Styles for the Todo List Container */
.auditsFilter {
  border-style: groove;
  border-color: #a5bfdc; 
  padding: 2%;
  border-radius: 4px;
  margin-bottom: 2%;
}

.todo-list-container {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Reset Styles for the List */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Styles for Each Todo Card */
.NoteCardContent {
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 15px;
  margin-bottom: 20px; /* Creates space between cards */
}

/* Flex Container for Grouping Fields */
.info-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjusts spacing between items */
  padding-bottom: 0.5rem;
}

/* Styling for Text Elements */
.NoteText, p {
  margin: 0; /* Removes default margin */
  text-align: left;
  padding-right: 1rem;

  font-size: 0.9rem;
  cursor: pointer;
}

/* Styles for the container of each row of fields */
.form-row-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px; /* Adds space between the rows */
}

/* Styles for individual form fields (assuming they're wrapped in divs) */
.form-row-group > div {
  flex: 1;
  min-width: calc(50% - 10px); /* Adjust based on gap to prevent items from becoming too narrow */
}

/* Button Style Adjustments */
.delete-btn {
 
  background-color: #FF1744;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1px; /* Space from the last info group */
  
}

/* Consolidated Filter Container and Item Styles */
.filter-container, .NotesContainer .filter-container {
  display: flex;
  justify-content:space-between;
  align-items:first baseline;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 1cap;
}

.filter-item, .NotesContainer .filter-item {
  display: flex;

  justify-content: center; /* Centers content within each filter-item vertically */
}

.filter-item input, .filter-item select {
  height: 36px; /* Provides a consistent height for all input/select elements */
  padding: 4px; /* Ensures padding is uniform */
}



.filter-item {
  display: flex;
  align-items: center; /* This vertically centers the label with the input */
  justify-content: space-between; /* This places the label on the left and the input on the right */
  margin-bottom: 10px; /* Adds some space between each filter item */
}

.filter-item label {
  margin-right: 10px; /* Adds some space between the label and the input */
  padding-bottom: 2%;
}

input[type="datetime-local"],
select {
  flex-grow: 1; /* Allows the input/select to grow and fill available space */
}
