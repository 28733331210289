.profileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 300px;
    margin: 20px auto;
    background-color: #fff;
  }
  
  .profileImage {
    
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .fileInput,
  .uploadButton,
  .editButton {
    margin: 10px 0;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    display: block;
  }
  
  .uploadButton,
  .editButton {
    padding: 5px 7px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
    font-size: 12px;
  }
  
  .profileName {
    margin: 10px 0;
    font-weight: bold;
  }
  
  .profileBio {
    text-align: center;
    font-size: 14px;
  }
  