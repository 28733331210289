.form {
  border-style: groove;
  border-color: #a5bfdc;
  padding: 2%;
  border-radius: 4px;
  
}

.todo-form {
  border-style: groove;
  border-color: #a5bfdc; 
  padding: 2%;
  border-radius: 4px;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-bottom: 0%;
}

.form-row {
  display: flex;
  justify-content: space-between; /* Distribute space between fields */
  margin-bottom: 10px; /* Space between rows */
}

.form-row > div {
  flex: 1; /* Make each div take equal space in the row */
  margin-right: 10px; /* Space between each field */
}

.form-row > div:last-child {
  margin-right: 0; /* Remove margin for the last element */
}

/* Styles for input, select, and textarea to ensure they expand to fill their containers */
input, select, textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 2px; /* Adjust or remove based on layout */
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="text"] {
  padding: 8px; /* Correcting the typo and adding padding */
  border-radius: 4px;
}

button[type="submit"] {
  width: 100%;
  padding: 15px 25px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: large;
 
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Additional style for full-width textarea */
.full-width-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


input:focus, select:focus, textarea:focus {
  outline: none;
  border-color: #66afe9; /* Light blue border on focus */
  box-shadow: 0 0 8px rgba(102, 175, 233, .6); /* Soft glow effect */
}

@media (max-width: 768px) {
  .form-row > div {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 10px; /* Add space between rows */
  }
}
