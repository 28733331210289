/* ChangePassword.css */
.change-password-container {
    max-width: 300px;
    margin: 5px auto;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  

  
  .change-password-container form {
    display: flex;
    flex-direction: column;
  }
  
  .change-password-container form div {
    margin-bottom: 1px;
  }
  
  .change-password-container form label {
    font-size: 14px;
    margin-bottom: 1px;
    display: block;
    color: #666;
  }
  
  .change-password-container form input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .change-password-container form button {
    padding: 5px 8px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .change-password-container form button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-bottom: 15px;
  }
  